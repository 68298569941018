/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react'

import { graphql } from 'gatsby'
import PageWrap from '@src/components/PageWrap'
import { Block, Header, Paragraph, useTheme } from '@stage-ui/core'

// @ts-ignore
export default function Template({ data }) {
  const theme = useTheme()
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
    <PageWrap>
      <Header>{frontmatter.title}</Header>
      <Paragraph size="s" weight={400} color="gray500">
        {frontmatter.date}
      </Paragraph>
      <br />
      <Block
        mt="xl"
        style={{
          hr: {
            borderColor: theme.color.gray[300].hex(),
            borderWidth: 0,
            borderBottomWidth: '1px',
            margin: '2rem 0',
          },
          h1: {
            marginTop: '2rem',
            fontSize: '1.75rem',
          },
          h2: {
            marginTop: '1.75rem',
            fontSize: '1.5rem',
          },
          h3: {
            marginTop: '1.5rem',
            fontSize: '1.25rem',
          },
          p: {
            lineHeight: 1.8,
            fontSize: '1.25rem',
            marginTop: '1rem',
          },
          ul: {
            marginLeft: '2rem',
            li: {
              lineHeight: 1.2,
              fontSize: '1rem',
              marginTop: '0.5rem',
            },
          },
          a: {
            color: theme.color.primary.hex(),
            fontWeight: 600,
            fontSize: '1rem',
          },
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Block>
    </PageWrap>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`
